<template>
  <v-dialog
    eager
    id="addtopantry"
    v-model="show"
    :fullscreen="isPhone"
    max-width="1024"
    persistent
    scrollable
    overlay-opacity="0.97"
    @keydown.esc="cancel"
  >
    <NotificationContainer />
    <v-card
      v-touch:swipe.left="cancel"
      class="d-flex flex-column noscroll-x charcoal overflow-y-auto"
      tile
    >
      <BaseEdamanBadge class="edamam" />
      <BaseCloseDialogHeader heading="Add Recipe" @cancel="cancel" />
      <v-sheet rounded class="charcoal mx-2 mb-1" :style="charcoalTileMenu">
        <template>
          <v-card-actions class="py-0 mt-2 px-2">
            <v-text-field
              autofocus
              dark
              label="Recipe Name"
              name="recipeName"
              v-model="recipeName"
              required
              type="text"
              prepend-inner-icon="mdi-pencil"
              :counter="50"
              @blur="recipeName = capitalize(recipeName)"
            />
          </v-card-actions>
        </template>
        <v-card-actions class="pa-0">
          <v-textarea
            rows="3"
            filled
            dark
            clearable
            color="progressActive"
            label="Type ingredients (e.g 1 egg, 1 cup of flour)"
            ref="search"
            v-model="search"
            class="pa-0 mr-n4"
            @focus="$event.target.select()"
            @click:clear="clearFoods"
          />
        </v-card-actions>
        <v-card-actions class="pa-0 mt-n7">
          <v-spacer />
          <BaseActionButton
            ripple
            plain
            color="progressActive"
            icon="mdi-nutrition"
            @clickedThis="searchNutrients()"
            label="Get Nutrition"
          />
        </v-card-actions>
      </v-sheet>
      <BaseLoading :loaded="loaded" label="Searching Food Database" />
      <template v-if="nutrientsList.length == 0">
        <v-sheet class="charcoal paper--text pa-2 pt-0" :style="charcoalTile">
          <v-card-actions :class="textSizeLarge + ' pt-0'">
            TOTAL
            <v-spacer />
            <span :class="textSizeLarge + ' progressActive--text'">
              {{ Math.round(total_calories) }} KCal
            </span>
          </v-card-actions>
          <v-card-text class="pa-2 mt-2">
            <v-row dense>
              <v-col cols="6" class="mt-n4">
                <v-text-field
                  dark
                  color="progressActive"
                  label="Fats"
                  ref="fats"
                  v-model="fats"
                  class="enlarged-input-small"
                  type="number"
                  pattern="[0-9]*"
                  inputmode="decimal"
                  prepend-inner-icon="mdi-weight-gram"
                  @input="calculateCalories(false)"
                  @focus="$event.target.select()"
                />
              </v-col>
              <v-spacer />
              <v-col cols="3" class="mt-4" align="right">
                <v-progress-linear
                  height="22"
                  rounded
                  background-color="charcoal lighten-1"
                  color="hybrid"
                  :value="fats_percentage"
                >
                  <template v-slot:default="{ value }">
                    <strong class="paper--text">{{ value }}%</strong>
                  </template>
                </v-progress-linear>
              </v-col>
              <v-col cols="6" class="mt-n4">
                <v-text-field
                  dark
                  color="progressActive"
                  label="Total Carbs"
                  ref="carbs"
                  v-model="carbs"
                  class="enlarged-input-small"
                  type="number"
                  pattern="[0-9]*"
                  inputmode="decimal"
                  prepend-inner-icon="mdi-weight-gram"
                  @input="calculateCalories(false)"
                  @focus="$event.target.select()"
                />
              </v-col>
              <v-spacer />
              <v-col cols="3" class="mt-4" align="right">
                <v-progress-linear
                  height="22"
                  rounded
                  background-color="charcoal lighten-1"
                  color="gold"
                  :value="carbs_percentage"
                >
                  <template v-slot:default="{ value }">
                    <strong class="paper--text">{{ value }}%</strong>
                  </template>
                </v-progress-linear>
              </v-col>
              <v-col cols="6" class="mt-n2">
                <v-text-field
                  dark
                  color="progressActive"
                  label="Protein"
                  ref="proteins"
                  v-model="proteins"
                  class="enlarged-input-small"
                  type="number"
                  pattern="[0-9]*"
                  inputmode="decimal"
                  prepend-inner-icon="mdi-weight-gram"
                  @input="calculateCalories(false)"
                  @focus="$event.target.select()"
                />
              </v-col>
              <v-spacer />
              <v-col cols="3" class="mt-6" align="right">
                <v-progress-linear
                  height="22"
                  rounded
                  background-color="charcoal lighten-1"
                  color="progressActive"
                  :value="proteins_percentage"
                >
                  <template v-slot:default="{ value }">
                    <strong class="paper--text">{{ value }}%</strong>
                  </template>
                </v-progress-linear>
              </v-col>
              <v-col cols="6" class="mt-n2">
                <v-text-field
                  dark
                  color="progressActive"
                  label="Sugar Alcohol"
                  ref="sugara"
                  v-model="sugara"
                  class="enlarged-input-small"
                  type="number"
                  pattern="[0-9]*"
                  inputmode="decimal"
                  prepend-inner-icon="mdi-weight-gram"
                  @input="calculateCalories(false)"
                  @focus="$event.target.select()"
                />
              </v-col>
              <v-col cols="6" class="mt-n2">
                <v-text-field
                  dark
                  color="progressActive"
                  label="Fiber"
                  ref="fiber"
                  v-model="fiber"
                  class="enlarged-input-small"
                  type="number"
                  pattern="[0-9]*"
                  inputmode="decimal"
                  prepend-inner-icon="mdi-weight-gram"
                  @input="calculateCalories(false)"
                  @focus="$event.target.select()"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-sheet>
      </template>
      <v-spacer />
      <v-sheet rounded class="charcoalTileMenu transparent mx-2 mt-n mb-4">
        <v-card-actions
          class="py-0"
          :style="charcoalTile"
          v-if="total_calories > 0 && recipeName != ''"
        >
          <v-switch
            dark
            v-model="toToday"
            label="Add To Today?"
            tabindex="0"
            color="progressActive"
          />
          <v-spacer />
          <v-switch
            dark
            v-model="toPantry"
            label="Add To Pantry?"
            tabindex="0"
            color="progressActive"
          />
        </v-card-actions>
        <v-card-actions
          class="pl-0 pr-1 py-0"
          :style="charcoalTile"
          v-if="total_calories > 0 && recipeName != ''"
        >
          <v-select
            v-if="toToday"
            filled
            dark
            label="Meal Type"
            v-model="selectedMealType"
            return-object
            :prepend-inner-icon="selectedMealType.icon"
            :items="mealTypes"
            item-text="meal"
            item-value="meal"
            color="progressActive"
            item-color="success"
            :menu-props="{
              closeOnClick: true,
              maxHeight: 310,
              transition: 'fab-transition'
            }"
          >
          </v-select>
          <v-spacer />
          <BaseActionButton
            v-if="total_calories > 0 && recipeName != ''"
            text
            large
            dark
            icon="mdi-plus-circle-outline"
            label="Add"
            color="paper"
            class=" paper--text"
            @clickedThis="saveAll()"
          />
        </v-card-actions>
      </v-sheet>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'
//import moment from 'moment/src/moment'
const NotificationContainer = () =>
  import(/* webpackPrefetch: true */ '@/components/NotificationContainer.vue')
import { isMobile } from 'mobile-device-detect'
import moment from 'moment/src/moment'
export default {
  components: { NotificationContainer },
  name: 'AddToPantry',
  mixins: [util],
  data() {
    return {
      mobile: isMobile,
      textFieldProps: {
        dark: true,
        appendIcon: 'mdi-calendar'
      },
      dateProps: {
        headerColor: 'charcoal'
      },
      timeProps: {
        headerColor: 'charcoal',
        useSeconds: false,
        ampmInTitle: true
      },
      switchNum: 0,
      barCode: null,
      loaded: false,
      selectedFood: {},
      isNow: true,
      noresults: false,
      carbs: 0,
      netcarbs: 0,
      proteins: 0,
      fats: 0,
      fiber: 0,
      sugara: 0,
      proteins_calories: 0,
      fats_calories: 0,
      carbs_calories: 0,
      proteins_percentage: 0,
      fats_percentage: 0,
      carbs_percentage: 0,
      sugara_calories: 0,
      fiber_calories: 0,
      total_calories: 0,
      search: '',
      nutrients: null,
      favoritesNum: 0,
      pantryOnly: false,
      recipeName: '',
      selectedMealType: {},
      mealTypes: [],
      nutrientsList: [],
      recipeData: null,
      datetime: null,
      message: '',
      toToday: false,
      toPantry: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('barcodescanned', this.barcodeScanned)
      window.addEventListener('message', this.receiveMessage)
    })
  },
  beforeDestroy() {
    window.removeEventListener('barcodescanned', this.barcodeScanned)
    window.removeEventListener('message', this.receiveMessage)
  },
  beforeMount() {
    this.reset()
  },

  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...appConfig
  },
  watch: {
    show(val) {
      this.$store.dispatch('session/toggleModal', val)
      if (!val) this.reset()
    }
  },
  methods: {
    receiveMessage(event) {
      if (event.data.type == 'CLOSE_MODAL' && event.data.key == 'UoMc92xqvA') {
        this.cancel()
      }
    },
    barcodeScanned(event) {
      if (
        event.detail.message == 'BARCODE-WAS-FOUND' &&
        event.detail.key == 'jyQ8aCYHBy'
      ) {
        this.message = event.detail.messageText
        this.search = event.detail.data
        this.searchNutrients()
      } else if (
        event.detail.message == 'CANCEL_SCAN' &&
        event.detail.key == 'jyQ8aCYHBy'
      )
        this.message = event.detail.messageText
      else null
    },
    manageScan() {
      this.enableBarcodeScan()
    },
    cancel() {
      this.reset()
      this.$emit('cancel')
    },

    reset() {
      this.loaded = true
      this.datetime = ''
      this.recipeName = ''
      this.proteins = 0
      this.carbs = 0
      this.fats = 0
      this.fiber = 0
      this.netcarbs = 0
      this.fats_calories = 0
      this.carbs_calories = 0
      this.proteins_calories = 0
      this.total_calories = 0
      this.isNow = true
      this.nutrientsList = []
      this.nutrients = null
      this.search = ''
      this.clearFoods()
      this.loadMealTypes()
    },
    calculateCalories(skipTotal) {
      let diff = this.carbs - this.fiber
      this.netcarbs = diff > 0 ? diff : 0
      this.proteins_calories = this.twoDigits(this.proteins * 4)
      this.carbs_calories = this.twoDigits(this.netcarbs * 4)
      this.fats_calories = this.twoDigits(this.fats * 9)
      this.sugara_calories = this.twoDigits(this.sugara * 2)
      this.fiber_calories = this.twoDigits(this.fiber * 2)

      if (skipTotal)
        this.total_calories = this.nutrients.totalNutrients.ENERC_KCAL.quantity
      else
        this.total_calories =
          this.proteins_calories +
          this.fats_calories +
          this.carbs_calories +
          this.sugara_calories +
          this.fiber_calories

      this.proteins_percentage = Math.round(
        (this.proteins_calories / this.total_calories) * 100
      )
      this.fats_percentage = Math.round(
        (this.fats_calories / this.total_calories) * 100
      )
      this.carbs_percentage =
        100 - this.proteins_percentage - this.fats_percentage
    },

    loadMealTypes() {
      {
        return axios
          .get(this.baseURL + '/nutrition/mealtypes', {})
          .then(response => {
            let sel = null
            if (response.status == 200) {
              this.mealTypes = response.data.data
              sel = this.mealTypes[0]
              this.mealTypes.forEach(el => {
                if (
                  el.primary == 'Yes' &&
                  moment().isBetween(
                    moment(el.start, 'hh:mm:ss'),
                    moment(el.end, 'hh:mm:ss')
                  )
                )
                  sel = el
              })

              this.selectedMealType = sel
              this.loaded = true
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    saveAll() {
      if (this.toPantry) this.savePantry()
      if (this.toToday) this.saveToday()
    },
    saveToday() {
      let mealTime = this.now()

      return axios
        .post(this.baseURL + '/nutrition/meal', {
          type: this.selectedMealType.meal,
          reuse: false,
          unit: 'Serving',
          qty: 1,
          name: this.recipeName,
          fid: this.foodId,
          proteins: this.proteins,
          fats: this.fats,
          carbs: this.carbs,
          sugara: this.sugara,
          netcarbs: this.netcarbs,
          fiber: this.fiber,
          calories: this.total_calories,
          allnutrients:
            this.nutrients != null ? this.nutrients.totalNutrients : null,
          meal_time: mealTime
        })
        .then(response => {
          if (response.status == 200) {
            this.reset()
            this.toast('Meal was succesfully added.')
            this.$emit('done')
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    savePantry() {
      return axios
        .post(this.baseURL + '/nutrition/pantry', {
          unit: 'Serving',
          unitWeight: this.recipeData.totalWeight,
          qty: 1,
          name: this.recipeName,
          proteins: this.proteins,
          fats: this.fats,
          carbs: this.carbs,
          sugara: this.sugara,
          netcarbs: this.netcarbs,
          fiber: this.fiber,
          calories: this.total_calories,
          allnutrients: this.recipeData.totalNutrients,
          fid: null
        })
        .then(response => {
          if (response.status == 200) {
            this.reset()
            this.toast('Meal was succesfully added.')
            this.$emit('done')
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    clearFoods() {
      this.nutrientsList = []
    },
    searchNutrients() {
      if (this.search.length < 2) return
      this.loaded = false
      this.search = this.search.trim()
      this.nutrientsList = []

      return axios
        .post(this.baseURL + '/nutrition/recipe', {
          title: 'New Search',
          ingr: this.search
        })
        .then(response => {
          if (response.status == 200) {
            if (response.data.data.length == 0) this.noresults = true
            else {
              this.recipeData = response.data.data
              this.total_calories = this.recipeData.calories
              this.fats_calories = this.recipeData.totalNutrientsKCal.FAT_KCAL
              this.carbs_calories = this.recipeData.totalNutrientsKCal.CHOCDF_KCAL
              this.proteins_calories = this.recipeData.totalNutrientsKCal.PROCNT_KCAL
              this.fats = this.twoDigits(
                this.recipeData.totalNutrients['FAT'].quantity
              )
              this.carbs = this.twoDigits(
                this.recipeData.totalNutrients['CHOCDF'].quantity
              )
              this.proteins = this.twoDigits(
                this.recipeData.totalNutrients['PROCNT'].quantity
              )
              this.sugara = 0
              this.fiber = this.twoDigits(
                this.recipeData.totalNutrients['FIBTG'].quantity
              )
              this.calculateCalories()
            }
            this.loaded = true
          } else {
            this.noresults = true
            this.loaded = true
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>
<style scoped>
.edamam {
  z-index: 99;
  position: absolute;
  left: 160px;
  top: 6px;
}
</style>
